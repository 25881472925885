<template>
  <div>
    <div class="Careers">
      <Top :info="topInfo">
        <Button
          size="large"
          @click="gotoFooter"
          style="margin-top: 40px"
          type="warning"
          >加入我们</Button
        >
      </Top>
      <div class="text1 center warp">在IC Bench工作是什么感觉？</div>
      <div class="text2 center warp">CMU大牛, 团队强, 氛围好，发展空间大</div>
      <div class="box warp">
        <div class="card w33">
          <div class="card-main">
            <div class="imgbox">
              <img src="@/assets/remuneration.jpg" />
            </div>
            <div class="textbox">
              <div class="textbox-title">待遇及福利</div>
              <div>
                年薪 <span style="font-size: 30px; font-weight: 600">30</span>万
                起
              </div>
              <div>
                大佬云集、五险一金、生日福利、节假日福利、零食下午茶、年度体检...
              </div>
            </div>
          </div>
        </div>
        <div class="card w33">
          <div class="card-main">
            <div class="imgbox">
              <img src="@/assets/location.jpg" />
            </div>
            <div class="textbox">
              <div class="textbox-title">工作地点</div>
              <div>一线城市（上海）+行业核心领军区域：</div>
              <div>上海市浦东新区，张江高科技园区;</div>
              <div>紧靠地铁、公园、商场，周边设施齐全。</div>
            </div>
          </div>
        </div>
        <div class="card w33">
          <div class="card-main">
            <div class="imgbox">
              <img src="@/assets/process.jpg" />
            </div>
            <div class="textbox">
              <div class="textbox-title">应聘流程</div>
              <div>简历投递→面试→Offer签发→入职</div>
              <div>应届生可在签订三方后选择带薪实习，毕业后正式入职。</div>
            </div>
          </div>
        </div>
        <div class="card w50">
          <div class="card-main">
            <div class="imgbox">
              <img src="@/assets/team.jpg" />
            </div>
            <div class="textbox h230">
              <div class="textbox-title">团队亮点</div>
              <div class="textbox-title2">超强的团队 + 巨大的个人发展空间</div>
              <ul>
                <li>全球顶级专家：少年班、清华、上交大、CMU、...</li>
                <li>顶尖资本加持：成立伊始即获得全球顶级资本投资</li>
                <li>超强管理团队：C-level/VP-level Exec Experience</li>
                <li>成功商业发展：One Nasdaq IPO; One Merger</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card w50">
          <div class="card-main">
            <div class="imgbox">
              <img src="@/assets/market.jpg" />
            </div>
            <div class="textbox h230">
              <div class="textbox-title">职业前景</div>
              <div class="textbox-title2">成为行业的领导者 + 极速上升的行业前景</div>
              <ul>
                <li>
                  电子设计自动化（EDA）软件号称高科技中的高科技，是芯片设计和制造里的MUST-HAVE。加入伴芯，和公司一起成为芯片行业的领导者！
                </li>
                <li>
                  数字化转型席卷各个领域，作为电子产业的基础，半导体发挥着核心 的作用。今后5-10年，是芯片行业的上升窗口期！
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="text1 center warp">职位信息</div>
      <div class="warp cardbox">
        <div class="card-item">
          <div class="card-title"  :style="{backgroundColor:jobs.software.info.backgroundColor}"></div>
          <div class="card-main">
            <div class="card-main-title">{{ jobs.software.info.jobTitle }}</div>
          </div>
          <a
            class="card-detail"
            @click="$router.push({ name: 'JOB', params: jobs.software })"
            >点击查看详细信息</a
          >
        </div>
      </div>
      <div class="warp cardbox">
        <div class="card-item">
          <div class="card-title" :style="{backgroundColor:jobs.fullStack.info.backgroundColor}"></div>
          <div class="card-main">
            <div class="card-main-title">
              {{ jobs.fullStack.info.jobTitle }}
            </div>
          </div>
          <a
            class="card-detail"
            @click="$router.push({ name: 'JOB', params: jobs.fullStack })"
            >点击查看详细信息</a
          >
        </div>
      </div>
      <div class="warp cardbox">
        <div class="card-item">
          <div class="card-title" :style="{backgroundColor:jobs.embeddedSoftware.info.backgroundColor}"></div>
          <div class="card-main">
            <div class="card-main-title">
              {{ jobs.embeddedSoftware.info.jobTitle }}
            </div>
          </div>
          <a
            class="card-detail"
            @click="
              $router.push({ name: 'JOB', params: jobs.embeddedSoftware })
            "
            >点击查看详细信息</a
          >
        </div>
      </div>
      <div class="warp cardbox">
        <div class="card-item">
          <div class="card-title" :style="{backgroundColor:jobs.asic.info.backgroundColor}"></div>
          <div class="card-main">
            <div class="card-main-title">{{ jobs.asic.info.jobTitle }}</div>
          </div>
          <a
            class="card-detail"
            @click="$router.push({ name: 'JOB', params: jobs.asic })"
            >点击查看详细信息</a
          >
        </div>
      </div>
      <div class="warp mail">
        <div class="mail-text1">简历发送至邮箱</div>
        <Button
          @click="$refs.mail.click()"
          type="warning"
          size="large"
          class="but"
          >hr@icbench.com</Button
        >
        <a
          id="mail"
          ref="mail"
          href="mailto:hr@icbench.com?subject=&body="
          v-show="false"
        ></a>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import careersImg from "@/assets/careers.jpg";
export default {
  name: "Careers",
  data() {
    return {
      jobs: {
        software: {
          info: {
            backgroundColor: "#9cbbc0",
            jobTitle: "软件工程师",
            jobDescription: "",
            jobResponsibility: [
              "具备良好的算法基础，能够熟练运用复杂数据结构；",
              "具备Linux平台下的编程和操作经验, 有良好的工程实现能力，熟练掌握一两门编程语言(C/C++/Python/Golang/Java等)；",
              "对编译器、操作系统有相关经验者优先。",
            ],
            jobQualifications: [
              "重点高校本科及以上学历，计算机、电子、通信、数学、物理等相关专业；",
              "善于思考，学习能力强，能独立分析和解决问题，有良好的沟通能力及团队合作精神；",
              "有实习项目经验者优先考虑。",
            ],
          },
        },
        fullStack: {
          info: {
            backgroundColor: "#6fb5cd",
            jobTitle: "全栈工程师",
            jobDescription: "",
            jobResponsibility: [
              "有一定的原生HTML、CSS、Javascript/ES6+, DOM等基础；有Vue、Angular2开发经验者优先考虑；",
              " 了解web/移动端等特性，熟悉开发流程，有组件化思想，能熟练使用Webpack、Git进行项目开发和维护；",
              "至少熟悉一种后端语言。",
            ],
            jobQualifications: [
              "重点高校本科及以上学历，计算机、电子、通信、数学、物理等相关专业；",
              "善于思考，学习能力强，能独立分析和解决问题，有良好的沟通能力及团队合作精神；",
              "有实习项目经验者优先考虑。",
            ],
          },
        },
        embeddedSoftware: {
          info: {
            backgroundColor: "#3070a5",
            jobTitle: "嵌入式软件工程师",
            jobDescription: "",
            jobResponsibility: [
              "本科及以上学历，熟练使用嵌入式C/C++语言编程，能独立进行软件开发；",
              "具备一定的硬件基础，熟悉各种单片机以及外围电路的应用和设计；",
              "熟悉MCU常用外设协议，如USB. SPI. UART. I2C等；",
              "熟悉嵌入式RTOS系统和系统构建，如内核裁剪，驱动移植，gpio子系统，mtd子系统，Makefile，shell脚本等；",
              "对代码质量有严格要求，有良好的编码风格与习惯；",
              " 参于需求分析；技术评估，撰写设计方案；开发文档。",
            ],
            jobQualifications: [
              "重点高校本科及以上学历，计算机、电子、通信、数学、物理等相关专业；",
              "善于思考，学习能力强，能独立分析和解决问题，有良好的沟通能力及团队合作精神；",
              "有实习项目经验者优先考虑。",
            ],
          },
        },
        asic: {
          info: {
            backgroundColor: "#0050b3",
            jobTitle: "芯片设计工程师",
            jobDescription: "负责ASIC设计，负责交付产品的质量和进度。",
            jobResponsibility: [
              "完成模块级结构设计，RTL实现以及相关验证工作；",
              "完成芯片前端设计实现，负责芯片整体性能的达成及优化；",
              "配合芯片综合，DFT等后端的设计和实现；",
              "参与FPGA的验证和系统调试工作；",
              "拟定芯片测试方案和支持芯片测试工作。",
            ],
            jobQualifications: [
              "微电子/电子/通信/计算机/自动化等相关专业，硕士及以上学历；",
              "精通Verilog RTL设计语言, 参与过至少一款成功流片的芯片开发项目优先；",
              "理解数字电路设计和时序逻辑设计等相关技术，有相关综合，DFT及时序分析经验者为佳；",
              "熟悉数字芯片设计和开发（从RTL到GDSII）流程，熟练使用常用的EDA工具；",
              "了解脚本语言(TCL/Perl/Python)及相关工具开发经验者优先；",
              "有责任心和团队合作精神、有较强的抗压能力; 有较强的动手能力和探知欲。",
            ],
          },
        },
      },
      topInfo: {
        background: {
          color: "rgba(0, 71, 157, 0.2)",
          image: careersImg,
          size: "cover",
          position: "center",
        },
        text: {
          title: "加入我们",
          explain: "Pursue your PASSION!",
          style: {
            textAlign: "left",
            color: "#fff",
          },
          explainStyle:{
            fontSize:"36px",
          }
        },
      },
    };
  },
  methods: {
    gotoFooter() {
      let windowHeight =
        document.body.scrollHeight || document.documentElement.scrollHeight;
      window.scrollTo(0, windowHeight);
    },
  },
  created() {},
};
</script>
<style lang='less' scoped>
.Careers {
  color: #666;
  letter-spacing: 2px;
  .circle {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: solid 3px#FFBD1E;
    margin-right: 15px;
  }
  .center {
    text-align: center;
  }
  .w33 {
    width: 33.33%;
  }
  .w50 {
    width: 50%;
  }
  @color1: #096dd9;
  @color2: #448aca;
  @color3: #bae7ff;
  .title {
    color: #00479d;
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 4px;
  }
  .text1 {
    margin-top: 100px;
    .title;
  }
  .text2 {
    margin-top: 20px;
  }
  .box {
    display: flex;
    margin-top: 50px;
    flex-wrap: wrap;
    .card {
      padding: 10px;
      .card-main {
        border: 1px solid #00479d;
        border-radius: 5px;
        overflow: hidden;
        .imgbox {
          display: flex;
          width: 100%;
          height: 207px;
          img {
            object-fit: cover;
          }
        }
        .textbox {
          padding: 20px;
          height: 200px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .textbox-title {
            .title;
          }
          ul {
            padding-left: 15px;
            line-height: 24px;
          }
          .textbox-title2 {
            font-weight: 600;
          }
        }
        .h230 {
          height: 230px;
        }
      }
    }
  }
  .card-item {
    display: flex;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 40px;
    box-shadow: 0 2px 12px 0 #ccc;
    position: relative;
    .card-detail {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 40px;
      color: #ff9900;
    }
    .card-title {
      width: 20px;
      padding: 10px;
      color: #fff;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      background-color: @color1;
    }
    .card-main {
      padding: 10px 20px 10px;
      .card-main-title {
        font-size: 20px;
      }
    }
  }
  .cardbox {
    padding: 0 15px;
  }
  .mail {
    padding: 100px 0;
    text-align: center;
    .mail-text1 {
      margin-bottom: 15px;
      color: #ff9900;
      letter-spacing: 3px;
    }
    .but {
      padding: 0 80px;
      letter-spacing: 3px;
    }
  }
}
</style>